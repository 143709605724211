import React, { ReactElement } from "react"
import styled, { css } from "styled-components"
import { QueryResult } from "../../../types"
import { AppFunctionComponent } from "../../../types"
import Loader from "../../common/loader.component"
import ResourceMissItem from "./resource-miss-item"
import { Resource, Tag as TagProps } from "./resource.interface"
import ResourcesListItem from "./resources-list-item.component"

const Container = styled.div`
  position: relative;
`

const List = styled.ul<{ pending?: boolean }>`
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  grid-auto-rows: minmax(346px, auto);
  list-style-type: none;
  opacity: 1;
  ${({ pending }) =>
    pending &&
    css`
      opacity: 0;
    `}
`

const NoResults = styled(Loader)`
  color: ${({ theme }) => theme.color.text};
`

const AbsoluteLoader = styled(Loader)`
  position: absolute;
  width: 100%;
`

interface Props {
  resources: Resource[]
  pagination: ReactElement
  pending?: boolean
  tags: QueryResult<TagProps>
}

const ResourcesList: AppFunctionComponent<Props> = ({
  resources,
  pagination,
  pending,
  tags,
}) => {
  const oneBeforeLast = resources.length - 1
  const moreThanOne = resources.length > 1
  return (
    <Container>
      {pending && <AbsoluteLoader />}
      <>
        {resources.length ? (
          <>
            <List pending={pending}>
              {resources.map((resource, index: number) => (
                <React.Fragment key={resource.id}>
                  {index === oneBeforeLast && moreThanOne && (
                    <ResourceMissItem tags={tags} />
                  )}
                  <ResourcesListItem {...resource} />
                  {resources.length === 1 && <ResourceMissItem tags={tags} />}
                </React.Fragment>
              ))}
            </List>
            {pagination}
          </>
        ) : (
          !pending && (
            <>
              <NoResults>No results found.</NoResults>
              <List pending={pending}>
                <ResourceMissItem tags={tags} />
              </List>
            </>
          )
        )}
      </>
    </Container>
  )
}

export default ResourcesList
