import { useEffect, useState } from "react"
import { useLocation } from "react-use"
import { BlogPostPreview } from "../components/community/blog/blog.interface"
import { Resource } from "../components/community/resources/resource.interface"
import { ContentType, QueryName } from "../constants/Query"
import { onQueryChange } from "./route-listener"

interface Props {
  type: string
  page?: number
  filters?: string
  slug?: string
  limit?: number
}

interface PaginationType {
  basePath?: string
  page: number
  totalPages: number
  total?: number
  limit?: number
}

export function getFilteredContent({
  type,
  filters = "",
  page,
  slug,
  limit,
}: Props) {
  const query = new URLSearchParams()
  query.append("type", type)
  if (page) {
    query.append("page", page.toString())
  }
  if (slug) {
    query.append("category", slug)
  }
  if (limit) {
    query.append("limit", limit.toString())
  }
  return fetch(
    `/.netlify/functions/search?${query.toString()}&${
      filters.toString().includes("/")
        ? filters.toString().replace("/", "")
        : filters.toString()
    }`
  )
    .then((response) => response.json())
    .then(({ items, pagination }) => ({
      items,
      pagination: {
        page: pagination.page,
        totalPages: Math.ceil(
          Number(pagination.total) / Number(pagination.limit)
        ),
      },
    }))
}

export interface FilteredContentProps<T = Resource[] | BlogPostPreview[]> {
  allItems: T
  totalPages: number
  basePath: string
  slug: string
  page: number
  contentType: ContentType
  limit?: number
}

export interface FilteredContentReturnType<T = Resource[] | BlogPostPreview[]> {
  pending: boolean
  items: T
  pagination: PaginationType
  queryParams: string
}

export const useFilteredContent = <T>({
  allItems,
  totalPages,
  basePath,
  slug,
  page = 1,
  contentType,
  limit = 10,
}: FilteredContentProps<T>): FilteredContentReturnType<T> => {
  const currentLocation = useLocation()

  const [paginationParams, setPaginationParams] = useState({
    page,
    totalPages,
    basePath,
  })

  const setInitialPagination = (): void =>
    setPaginationParams({ page, totalPages, basePath })
  const hasQueryParams = (): boolean =>
    Boolean(currentLocation.search && currentLocation.search.length > 1)

  const getFiltersFromRoute = (): string =>
    currentLocation.search && currentLocation.search?.includes(QueryName.Tag)
      ? currentLocation.search.toString().substr(1)
      : ""
  const [filters, setFilters] = useState(getFiltersFromRoute())
  const [filteredItems, setFilteredItems] = useState<T>([] as unknown as T)
  const [pending, setPending] = useState(hasQueryParams())

  onQueryChange(() => {
    setFilters(getFiltersFromRoute())
  })

  useEffect(() => {
    setPending(true)
    if (hasQueryParams() && currentLocation.search?.includes(QueryName.Tag)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getFilteredContent({
        type: contentType,
        filters,
        page,
        slug,
        limit,
      }).then(({ items, pagination }) => {
        setFilteredItems(items)
        setPending(false)
        setPaginationParams({
          ...pagination,
          basePath,
        })
      })
    } else {
      setPending(false)
      setInitialPagination()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return {
    pending,
    items: filters.length ? filteredItems : allItems,
    pagination: paginationParams,
    queryParams: filters.length ? `?${filters}` : "",
  }
}
