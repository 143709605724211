import React from "react"
import styled from "styled-components"
import { useQueryFilters } from "../../../helpers/filter-tags"
import flattenQueryResult from "../../../helpers/flatten-query-result"
import { AppFunctionComponent, QueryResult } from "../../../types"
import { QuinaryHeading } from "../../common/heading.component"
import Tag from "../../common/tag.component"
import { Tag as TagProps } from "./resource.interface"

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
`
const ResourceTag = styled(Tag)`
  margin-bottom: 5px;
  margin-right: 5px;
`

interface Props {
  basePath: string
  oneFilterAtTime?: boolean
  tagContent: QueryResult<TagProps>
}

const ResourcesFilters: AppFunctionComponent<Props> = ({
  tagContent,
  basePath,
  oneFilterAtTime = false,
}) => {
  const { resolveTag, activeTags } = useQueryFilters(basePath, oneFilterAtTime)

  const allTag = {
    slug: "",
    text: "All",
  }

  const resolvedTags = [
    ...(oneFilterAtTime ? [allTag] : []),
    ...flattenQueryResult<TagProps>(tagContent),
  ]

  return (
    <TagsList
      tags={resolvedTags}
      onTagActivation={resolveTag}
      activeTags={activeTags}
    />
  )
}

interface Tags {
  readonly tags: ReadonlyArray<TagProps>
  readonly activeTags: ReadonlyArray<string>
  readonly onTagActivation: (slug: string) => void
}

const TagsList: AppFunctionComponent<Tags> = ({
  tags,
  onTagActivation,
  activeTags,
}) => {
  const tagsList = tags as TagProps[]
  return (
    <>
      <QuinaryHeading renderAs={"h5"}>Filter by:</QuinaryHeading>
      <TagsContainer>
        {tagsList.map(({ slug, text }) => {
          const onClick = () => onTagActivation(slug)
          const active =
            activeTags.includes(slug) ||
            (activeTags.length === 0 && slug === "")
          return (
            <ResourceTag onClick={onClick} active={active} key={slug}>
              {text}
            </ResourceTag>
          )
        })}
      </TagsContainer>
    </>
  )
}

export default ResourcesFilters
