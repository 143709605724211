import React from "react"
import { AppFunctionComponent } from "../../../types"

interface Props {
  count?: number
}

const CommunityCommentsCount: AppFunctionComponent<Props> = ({ count }) => {
  const countString =
    count || count === 0
      ? count > 1
        ? `${count} Comments`
        : count > 0
        ? `${count} Comment`
        : "Be the first to comment"
      : "Loading comments ..."
  return <>{countString}</>
}

export default CommunityCommentsCount
