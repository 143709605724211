import React, { FormEvent, useState } from "react"
import { useKeyDown } from "../../../helpers/use-key-down-event.hook"
import ExclamationMark from "../../../images/exclamation-mark.inline.svg"
import LikeIcon from "../../../images/like.inline.svg"
import { Key } from "../../../constants/Key"
import Thanks from "../../common/thanks.component"
import ResourceModalForm from "./resource-modal-form.component"
import { Modal, ModalType } from "../../../ui/index"
import { QueryResult } from "../../../types"
import { Tag as TagProps } from "./resource.interface"
import { AppFunctionComponent } from "../../../types"

const time = 250

interface Props {
  hideModal: () => void
  active: boolean
  tags: QueryResult<TagProps>
}

interface Error {
  url: {
    message: string
    warning: boolean
  }
  tags: {
    message: string
    warning: boolean
  }
}

const ResourceModal: AppFunctionComponent<Props> = ({
  hideModal,
  active,
  tags,
}) => {
  const [url, setUrl] = useState("")
  const [description, setDescription] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [responseText, setResponseText] = useState("")
  const [sentFormData, setSentFormData] = useState(false)
  const [pending, setPendingState] = useState(false)
  const [formErrors, setFormErrors] = useState<Error>({
    url: { message: "", warning: false },
    tags: { message: "", warning: false },
  })
  const [success, setSuccess] = useState(false)

  const iconComponent = success ? <LikeIcon /> : <ExclamationMark />

  const setInitialState = () => {
    setUrl("")
    setDescription("")
    setName("")
    setEmail("")
    setSelectedTags([])
    setResponseText("")
    setSentFormData(false)
    setPendingState(false)
    setFormErrors({
      url: { message: "", warning: false },
      tags: { message: "", warning: false },
    })
    setSuccess(false)
  }

  const closeModal = () => {
    hideModal()
    if (sentFormData) {
      setTimeout(() => {
        setInitialState()
      }, time)
    }
  }

  useKeyDown(closeModal, Key.Esc)

  const isValidURL = (link: string) => {
    const regex =
      /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/
    return regex.test(link)
  }

  const validateForm = () => {
    let errors: Error = {
      url: { message: "", warning: false },
      tags: { message: "", warning: false },
    }
    if (url === "") {
      errors = {
        ...errors,
        url: { message: "Missing field: URL address", warning: true },
      }
    } else if (!isValidURL(url)) {
      errors = {
        ...errors,
        url: { message: "Enter a full url with https or http", warning: true },
      }
    }
    if (selectedTags.length === 0) {
      errors = {
        ...errors,
        tags: { message: "You have to pick at least one topic", warning: true },
      }
    }
    return errors
  }

  const submitForm = (event: FormEvent) => {
    event.preventDefault()
    const errors = validateForm()
    setFormErrors(errors)

    if (!errors.url.warning && !errors.tags.warning) {
      setPendingState(true)

      const formData = new FormData(document.forms[0])
      formData.append("tags", selectedTags.join(", "))

      fetch(process.env.GATSBY_SCRIPT_URL!, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText)
          }
          return response.json()
        })
        .then((response) => {
          setSentFormData(true)
          const { status, error: responseError } = response
          if (status === 200) {
            setSuccess(true)
            setResponseText(
              "Thank you for submitting your resource recommendation!"
            )
          } else {
            if (status >= 500) {
              window.Rollbar.error(
                `Resource reccomendation Error  ${status}. ${responseError.message}`
              )
            }
            setResponseText(
              "Oops! Something went wrong. Please try again or contact us via email"
            )
          }
        })
        .catch((error) => {
          setSentFormData(true)
          window.Rollbar.error(`Resource reccomendation Error  ${error}`)
          setResponseText(
            "Oops! Something went wrong. Please try again or contact us via email"
          )
        })
    }
  }

  return (
    <Modal modalType={ModalType.Box} onClose={closeModal} active={active}>
      {!sentFormData ? (
        <ResourceModalForm
          url={url}
          setUrl={setUrl}
          description={description}
          setDescription={setDescription}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          pending={pending}
          submitForm={submitForm}
          formErrors={formErrors}
          tags={tags}
        />
      ) : (
        <Thanks onClose={closeModal} text={responseText} buttonText={"done"}>
          {iconComponent}
        </Thanks>
      )}
    </Modal>
  )
}

export default ResourceModal
