import { QueryName } from "../constants/Query"
import { dynamicSearchParams } from "./route-listener"

/*
 * Filters are based on route changing (query parameters)
 */

interface QueryFiltersResponse {
  resolveTag: (slug: string) => void
  activeTags: string[]
}

export const useQueryFilters = (
  basePath: string,
  oneFilterAtTime = false
): QueryFiltersResponse => {
  const [searchParams] = dynamicSearchParams()

  const isTagActive = (slug: string): boolean =>
    searchParams.getAll(QueryName.Tag).includes(slug)

  const removeTag = (slug: string): void => {
    const updatedTags = searchParams
      .getAll(QueryName.Tag)
      .filter((tag) => tag !== slug)
    searchParams.delete(QueryName.Tag)
    updatedTags.forEach((tag) => {
      searchParams.append(QueryName.Tag, tag)
    })
  }

  const removeAllTags = (): void => {
    searchParams.delete(QueryName.Tag)
  }

  const addTag = (slug: string): void =>
    searchParams.append(QueryName.Tag, slug)

  const slashEndedBasePath = basePath.endsWith("/") ? basePath : basePath + "/"
  const queryExist = (): boolean =>
    searchParams.toString().length > 1 &&
    searchParams.toString().includes(QueryName.Tag)

  const applyQuery = (): string =>
    queryExist()
      ? `${slashEndedBasePath}?${searchParams.toString()}`
      : slashEndedBasePath
  const changeUrl = (): void => {
    if (location.pathname > slashEndedBasePath) {
      location.href = applyQuery()
    } else if (queryExist()) {
      history.pushState(null, "", applyQuery())
    } else {
      history.pushState(null, "", slashEndedBasePath)
    }
  }

  const resolveTag = (slug: string): void => {
    if (slug.length) {
      if (isTagActive(slug)) {
        removeTag(slug)
      } else {
        if (oneFilterAtTime) {
          removeAllTags()
        }
        addTag(slug)
      }
    } else {
      removeAllTags()
    }
    changeUrl()
  }

  const activeTags = searchParams.getAll(QueryName.Tag).map((tag) => {
    return tag.includes("/") ? tag.replace("/", "") : tag
  })

  return {
    resolveTag,
    activeTags,
  }
}
