import React, { MouseEvent } from "react"
import styled, { css } from "styled-components"
import { AppFunctionComponent } from "../../types"

const tagStyle = css`
  font-family: ${({ theme }) => theme.font.display};
  font-size: ${14 / 16}rem;
  line-height: 1.7em;
  white-space: nowrap;
  font-weight: 400;
`

const activeStyle = css`
  background-color: ${({ theme }) => theme.color.tag};
  border-color: ${({ theme }) => theme.color.tag};
  color: #fff;
`

const TagElement = styled.div`
  ${tagStyle};
  margin: 0 15px 0 0;
`

const FocusableTagElement = styled.button<{ active: boolean }>`
  background: none;
  border: none;
  ${tagStyle};
  border: 2px solid ${({ theme }) => theme.color.backgroundAttention};
  border-radius: 20.5px;
  color: ${({ theme }) => theme.color.tag};
  padding: 3px 20px;
  margin-right: 5px;
  cursor: pointer;

  ${({ active }) => active && activeStyle};
`

interface Props {
  raw?: boolean
  active?: boolean
  onClick?: (() => any) | ((event: MouseEvent<HTMLButtonElement>) => void)
  children?: React.ReactNode
}

const Tag: AppFunctionComponent<Props> = ({
  raw,
  active = false,
  onClick,
  ...props
}: Props) => {
  const actionText = props.children
    ? `Filter by tag: ${props.children.toString()}`
    : undefined

  return raw ? (
    <TagElement {...props} />
  ) : (
    <FocusableTagElement
      onClick={onClick}
      active={active}
      aria-label={actionText}
      {...props}
    />
  )
}

export default Tag
