import React from "react"
import styled from "styled-components"
import { AppFunctionComponent } from "../../../types"

const Sidebar = styled.div``

const ResourcesSidebar: AppFunctionComponent = ({ children }) => (
  <Sidebar>{children}</Sidebar>
)

export default ResourcesSidebar
