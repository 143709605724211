import { graphql, useStaticQuery } from "gatsby"
import React, { FormEvent, MouseEvent } from "react"
import styled, { css } from "styled-components"
import flattenQueryResult from "../../../helpers/flatten-query-result"
import { generateSources } from "../../../helpers/images"
import {
  AppFunctionComponent,
  ContentfulSection,
  QueryResult,
} from "../../../types"
import { PrimaryHeading } from "../../common/heading.component"
import { RawLink } from "../../../ui/components/link/link.component"
import StandardSection, {
  SectionArticle,
  SectionImage,
  SectionLayout,
} from "../../common/sections/section.component"
import Tag from "../../common/tag.component"
import { TextInput } from "../../common/text-input.component"
import { TextareaInput } from "../../common/textarea-input/textarea-input.component"
import { Tag as TagProps } from "./resource.interface"
import { BasicButton } from "../../../ui/components/button/button.component"

const ModalDataWrapper = styled.div`
  margin: auto;
  width: 80%;
  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    width: 100%;
  }
`

const ModalHeading = styled(PrimaryHeading)`
  margin: 50px 5px 15px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    margin: 15px 0;
  }
`

const ModalParagraph = styled.p`
  margin: 0 5px 40px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`

const FormImage = styled(SectionImage)`
  display: none;
  margin-bottom: 5px;
  overflow-y: hidden;
  background-color: ${({ theme }) => theme.color.backgroundInverted};
  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    display: block;
  }
`

const Tags = styled.div`
  display: flex;
  margin-bottom: 40px;
  flex-wrap: wrap;
  div {
    margin: 2px;
  }
`

const TextInputNameEmail = styled(TextInput)`
  width: 100%;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  padding-bottom: 0px;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    flex-direction: row;
  }
`

const ModalTextInput = styled(TextInput)<{ warning?: boolean }>`
  margin: 0 0 40px 0;
  ${({ warning }) =>
    warning &&
    css`
      input {
        border: 1px solid ${({ theme }) => theme.color.error};
      }
    `}
`

const FormParagraph = styled.p<{ warning?: boolean }>`
  margin: 0 5px 10px 5px;
  ${({ warning }) =>
    warning &&
    css`
      margin-bottom: 0px !important;
    `};

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    margin-bottom: 10px;
  }
`

const FormSection = styled(StandardSection)`
  display: block;
  margin: 0;
  width: 100% !important;
  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    display: grid;
    grid-column-gap: 5%;
    grid-template-columns: 45% 50%;
  }
  ${SectionArticle} {
    height: 100%;
    padding: 0;
  }
`

const ButtonSection = styled.div`
  grid-column: 2;
  grid-row: 3;
  padding: 0 5px;

  ${BasicButton} {
    margin: 40px 0px 0px;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
      width: auto;
    }
  }
`

const PrivacyPolicy = styled.div`
  display: flex;
  margin-top: 15px;
  width: 100%;
  p {
    display: block;
    padding: 0 5px;
    text-align: justify;
    font-size: ${11 / 16}rem;
    color: ${({ theme }) => theme.color.newsletterAgreement};
  }
`

const WarningMessage = styled.p`
  display: block;
  padding: 0;
  margin: 5px;
  color: ${({ theme }) => theme.color.error};
  font-size: ${11 / 16}rem;
`

interface ModalQueryResult<I> {
  image: I
}

interface Props {
  url: string
  setUrl: (url: string) => void
  description: string
  setDescription: (description: string) => void
  name: string
  setName: (name: string) => void
  email: string
  setEmail: (email: string) => void
  selectedTags: string[]
  setSelectedTags: (selectedTags: string[]) => void
  pending: boolean
  submitForm: (event: FormEvent) => void
  formErrors: Error
  tags: QueryResult<TagProps>
}

interface Error {
  url: {
    message: string
    warning: boolean
  }
  tags: {
    message: string
    warning: boolean
  }
}

const ResourceModalForm: AppFunctionComponent<Props> = ({
  url,
  setUrl,
  description,
  setDescription,
  name,
  setName,
  email,
  setEmail,
  selectedTags,
  setSelectedTags,
  pending,
  submitForm,
  formErrors,
  tags,
}) => {
  const { image }: ModalQueryResult<ContentfulSection> = useStaticQuery(query)
  const tagsData = flattenQueryResult<TagProps>(tags)

  const isTagSelected = (text: string) => {
    return selectedTags.includes(text)
  }

  const removeTag = (text: string) => {
    const filtered = selectedTags.filter((tag) => {
      return tag !== text
    })
    setSelectedTags(filtered)
  }

  const addTag = (text: string) => {
    setSelectedTags([...selectedTags, text])
  }

  const onTagSelection = (text: string) =>
    isTagSelected(text) ? removeTag(text) : addTag(text)

  return (
    <ModalDataWrapper>
      <ModalHeading renderAs={"h5"}>
        Submit your resource recommendation
      </ModalHeading>
      <ModalParagraph>
        Share a resource you think should be featured on our website.
      </ModalParagraph>
      <form name="resource-recommendation" method="post" onSubmit={submitForm}>
        <FormSection layout={SectionLayout.ImageText}>
          <FormImage image={generateSources([[image.desktopImage]])} />
          <SectionArticle as="div">
            <FormParagraph>Enter resource URL address:</FormParagraph>
            <ModalTextInput
              type={"text"}
              name={"url"}
              placeholder={""}
              label={"URL address"}
              value={url}
              onValueChange={setUrl}
              minLength={3}
              warning={formErrors.url.warning}
            />
            <FormParagraph warning={formErrors.tags.warning}>
              Select topics you think this resource is related to:
            </FormParagraph>
            {formErrors.tags.warning && (
              <WarningMessage>{formErrors.tags.message}</WarningMessage>
            )}
            <Tags>
              {tagsData.map(({ text }, index) => {
                const onClick = (event: MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault()
                  onTagSelection(text)
                }
                const activeTag = selectedTags.includes(text)
                return (
                  <Tag
                    key={index}
                    onClick={(event) => onClick(event)}
                    active={activeTag}
                  >
                    {text}
                  </Tag>
                )
              })}
            </Tags>
            <FormParagraph>
              Tell us why you think this resource should be featured:
            </FormParagraph>
            <TextareaInput
              name={"description"}
              placeholder={""}
              label={"Type description"}
              value={description}
              onValueChange={setDescription}
              minLength={3}
              maxLength={500}
              resize={"vertical"}
            />
            <FormParagraph>
              Let us get back to you in case of questions or comments:
            </FormParagraph>
            <InputContainer>
              <TextInputNameEmail
                type={"text"}
                name={"name"}
                placeholder={""}
                label={"Your name"}
                value={name}
                onValueChange={setName}
                minLength={3}
                optional
              />
              <TextInputNameEmail
                type={"email"}
                name={"email"}
                placeholder={""}
                label={"Your email"}
                value={email}
                onValueChange={setEmail}
                minLength={3}
                optional
              />
            </InputContainer>
            {formErrors.url.warning && (
              <WarningMessage>{formErrors.url.message}</WarningMessage>
            )}
            <PrivacyPolicy>
              <p>
                By providing your e-mail you agree to receive emails from
                Mudita. Your data will be processed according to{" "}
                <RawLink to="https://mudita.com/legal/privacy-policy/">
                  Privacy Policy
                </RawLink>
              </p>
            </PrivacyPolicy>
          </SectionArticle>
          <ButtonSection>
            <BasicButton type="submit" pending={pending}>
              Submit
            </BasicButton>
          </ButtonSection>
        </FormSection>
      </form>
    </ModalDataWrapper>
  )
}

export default ResourceModalForm

const query = graphql`
  query ResourcesModalTagsQuery {
    image: contentfulSection(contentfulid: { eq: "resources/modal" }) {
      desktopImage: image {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 100
          width: 1260
        )
      }
    }
  }
`
