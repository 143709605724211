import React from "react"
import styled from "styled-components"
import { QueryResult } from "../../../types"
import { AppFunctionComponent } from "../../../types"
import Search from "../../common/search.component"
import StandardSection from "../../common/sections/section.component"
import { Category, Tag } from "./resource.interface"
import ResourcesFilters from "./resources-filters.component"
import ResourcesMenu from "./resources-menu.component"
import ResourcesSidebar from "./resources-sidebar.component"

const ResourcesLayout = styled(StandardSection)`
  grid-template-columns: auto;
  grid-template-areas: "Sidebar" "MainColumn";
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    display: grid;
    grid-template-columns: 1fr 270px;
    grid-template-areas: "MainColumn Sidebar";
    grid-column-gap: 40px;
    align-items: flex-start;
  }
`

const MainColumn = styled.div`
  grid-area: MainColumn;
  display: grid;
  grid-gap: 30px;
`

interface Link {
  to: string
  text: string
}

interface Props {
  categories: Category[]
  basePath: string
  tagContent: QueryResult<Tag>
}

const categoriesToLinks = (categories: Category[]): Link[] =>
  categories.map((category) => ({ to: category.slug, text: category.title }))

const ResourcesContent: AppFunctionComponent<Props> = ({
  children,
  categories,
  basePath,
  tagContent,
}) => (
  <ResourcesLayout>
    <ResourcesSidebar>
      <Search />
      <ResourcesFilters
        tagContent={tagContent}
        basePath={basePath}
        oneFilterAtTime
      />
    </ResourcesSidebar>
    <MainColumn>
      <ResourcesMenu links={categoriesToLinks(categories)} />
      {children}
    </MainColumn>
  </ResourcesLayout>
)

export default ResourcesContent
