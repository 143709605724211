import React from "react"
import styled from "styled-components"
import { AppFunctionComponent } from "../../types"

const SearchPlaceholder = styled.div`
  height: 35px;
`

const Search: AppFunctionComponent = () => (
  /* TODO: MW-68, MW-65 - search feature for Blog and Resources */
  <SearchPlaceholder />
)

export default Search
