import React from "react"
import styled from "styled-components"
import commentIcon from "../../../images/icons/comment.svg"
import hyperlinkIcon from "../../../images/icons/hyperlink.svg"
import muditaSignIcon from "../../../images/icons/mudita_sign.svg"
import CommunityCommentsCount from "../../common/community-comments-count/community-comments-count.component"
import { roundedBox } from "../../common/general-styles.component"
import { QuinaryHeading, SenaryHeading } from "../../common/heading.component"
import {
  BaseLink,
  NeutralRawLink,
  NeutralTextLink,
} from "../../../ui/components/link/link.component"
import Tag from "../../common/tag.component"
import { Resource } from "./resource.interface"
import { Image } from "../../common/image.component"
import { AppFunctionComponent } from "../../../types"

const ResourceElement = styled.li`
  ${roundedBox};
  background-color: ${({ theme }) => theme.color.background};
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "Image" "Text";
  grid-gap: 30px;
  position: relative;
  overflow: visible;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    grid-template-columns: auto 1fr;
    grid-template-areas: "Image Text";
    padding: 30px;
  }

  ${QuinaryHeading}, ${SenaryHeading}, p {
    margin-top: 0;
    margin-bottom: 15px;
  }

  ${SenaryHeading} {
    font-weight: 400;
  }
`

const MuditaSign = styled.div<{ content: string }>`
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: ${({ theme }) => theme.color.backgroundInverted};
  box-shadow: 0 0 32px 0 ${({ theme }) => theme.color.lightShadow};
  border-radius: ${({ theme }) => theme.size.borderRadiusSmall};
  z-index: 1;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    &:after {
      display: block;
      position: absolute;
      background-color: ${({ theme }) => theme.color.backgroundInverted};
      box-shadow: 0 0 32px 0 ${({ theme }) => theme.color.lightShadow};
      border-radius: 20px;
      content: "${({ content }) => content}";
      white-space: nowrap;
      padding: 3px 15px;
      font-family: ${({ theme }) => theme.font.display};
      font-size: 14px;
      top: -43px;
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
`

const ResourceImageWrapper = styled(BaseLink)`
  ${roundedBox};
  box-shadow: 0 2px 20px 0 ${({ theme }) => theme.color.lightShadow};
  grid-area: Image;
  width: 100%;
  height: 285px;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    width: 220px;
    min-height: 285px;
    height: 100%;
  }
`

const ResourceImage = styled(Image)`
  height: 100%;
  width: 100%;

  img {
    object-fit: cover;
  }
`
const Article = styled.article`
  grid-area: Text;
  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    padding-top: 15px;
    display: grid;
    grid-template-rows: auto auto 1fr auto;
  }
`

const Description = styled.p`
  min-height: 6em;
`

const Links = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.borderLight};
  padding-top: 18px;
  align-self: end;
  margin-bottom: -5px;

  a {
    color: ${({ theme }) => theme.color.text};
    white-space: nowrap;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    justify-content: space-between;
    display: flex;

    a:last-of-type {
      text-align: right;
    }
  }
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    margin-bottom: 0;
  }
`

const CommunityLink = styled(NeutralTextLink)`
  margin-top: -3px;

  img {
    margin-right: 10px;
    vertical-align: middle;
  }
`

const LinkSign = styled.span`
  white-space: nowrap;
`

type Props = Resource

const communityCommentsAvailable = false

const ResourcesListItem: AppFunctionComponent<Props> = ({
  title,
  description,
  authors,
  muditaOwned,
  tags,
  sourceUrl,
  forumThreadUrl,
  image,
}) => (
  <ResourceElement>
    {muditaOwned && (
      <MuditaSign content={"Mudita resource"}>
        <img src={muditaSignIcon} alt={"Mudita resource"} />
      </MuditaSign>
    )}
    <ResourceImageWrapper to={sourceUrl} aria-label={title}>
      <ResourceImage image={image.gatsbyImageData} alt="" />
    </ResourceImageWrapper>
    <Article>
      <SenaryHeading renderAs={"h6"}>
        {authors.map((item) => item.name).join(", ")}
      </SenaryHeading>
      <NeutralRawLink to={sourceUrl}>
        <QuinaryHeading renderAs={"h5"}>
          {title}
          <LinkSign>
            &nbsp;
            <img src={hyperlinkIcon} alt={""} />
          </LinkSign>
        </QuinaryHeading>
      </NeutralRawLink>
      <Description>{description}</Description>
      <Links>
        <Tags>
          {tags.map((tag) => (
            <Tag raw key={tag.slug}>
              {tag.text}
            </Tag>
          ))}
        </Tags>
        {communityCommentsAvailable && (
          <CommunityLink to={forumThreadUrl}>
            <img src={commentIcon} alt={"Comment"} />
            <CommunityCommentsCount
            // postId={5} // TODO: after posts integration add its ID
            />{" "}
            in forum
          </CommunityLink>
        )}
      </Links>
    </Article>
  </ResourceElement>
)

export default ResourcesListItem
