import React, { useEffect, useRef, useState } from "react"
import smoothscroll from "smoothscroll-polyfill"
import styled, { css } from "styled-components"
import useWindowEvent from "../../helpers/use-window-event.hook"
import { AppFunctionComponent } from "../../types"
import { NavigationArrow } from "./general-styles.component"

if (typeof window !== `undefined`) {
  smoothscroll.polyfill()
}

const SliderMenuWrapper = styled.div<{ visible: boolean }>`
  max-width: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  opacity: 0;
  visibility: hidden;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const SliderMenuList = styled.ul<{ arrowsVisible: boolean }>`
  padding: 0;
  z-index: 0;
  width: 100%;
  height: 65px;
  display: flex;
  overflow-x: auto;
  list-style: none;
  position: relative;
  margin: 0 0 -30px 0;
  flex-direction: row;
  box-sizing: border-box;

  li:first-of-type {
    padding-left: 15px;
  }

  li:last-of-type {
    padding-right: 15px;
    a {
      margin-right: 0;
    }
  }
`

export const SliderMenuItem = styled.li`
  box-sizing: border-box;
`

const ArrowWrapper = styled.div<{ visible: boolean }>`
  z-index: 2;
  width: 30px;
  height: 100%;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  display: ${({ visible }) => (visible ? "block" : "none")};

  &:first-of-type {
    margin-right: 5px;
    &:before {
      right: -20px;
      content: "";
      width: 20px;
      height: 100%;
      display: block;
      position: absolute;
      background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
    }

    ${NavigationArrow} {
      transform: rotateZ(180deg);
    }

    ${({ visible }) =>
      !visible &&
      css`
        & + ul > li {
          padding: 0;
        }
      `};
  }

  &:last-of-type {
    margin-left: 5px;
    &:before {
      left: -20px;
      content: "";
      width: 20px;
      height: 100%;
      display: block;
      position: absolute;
      background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
    }
  }

  ${NavigationArrow} {
    z-index: 3;
    width: 30px;
    height: 30px;
    position: relative;
  }
`

const isItemLI = (item: Element): item is HTMLLIElement => item.tagName === "LI"

enum ArrowsVisibilityState {
  Visible,
  Hidden,
  Unknown,
}

const SliderMenu: AppFunctionComponent = ({ children }) => {
  const sliderTrack = useRef<HTMLUListElement>(null)
  const [arrowsVisibility, setArrowsVisibility] = useState(
    ArrowsVisibilityState.Unknown
  )

  const isScrollable = () => {
    if (sliderTrack.current && sliderTrack.current.parentElement) {
      return sliderTrack.current.parentElement.clientWidth <=
        sliderTrack.current.scrollWidth
        ? ArrowsVisibilityState.Visible
        : ArrowsVisibilityState.Hidden
    } else {
      return ArrowsVisibilityState.Visible
    }
  }

  useWindowEvent("resize", () => setArrowsVisibility(isScrollable()))

  useEffect(() => {
    setArrowsVisibility(isScrollable())
  }, [sliderTrack])

  const slide = (prev = false) => {
    if (sliderTrack.current && sliderTrack.current.children) {
      for (const item of Array.from(sliderTrack.current.children)) {
        if (isItemLI(item)) {
          const itemPosition = item.offsetLeft + (!prev ? item.offsetWidth : 0)
          const trackPosition =
            sliderTrack.current.scrollLeft +
            (!prev ? sliderTrack.current.offsetWidth : 0)
          if (itemPosition > trackPosition) {
            sliderTrack.current.scroll({
              top: 0,
              left:
                item.offsetLeft - (prev ? sliderTrack.current.offsetWidth : 0),
              behavior: "smooth",
            })
            break
          }
        }
      }
    }
  }

  const slidePrev = () => {
    slide(true)
  }

  const slideNext = () => {
    slide(false)
  }

  const arrowsVisible = arrowsVisibility === ArrowsVisibilityState.Visible
  return (
    <SliderMenuWrapper
      visible={arrowsVisibility !== ArrowsVisibilityState.Unknown}
    >
      <ArrowWrapper visible={arrowsVisible} onClick={slidePrev}>
        <NavigationArrow />
      </ArrowWrapper>
      <SliderMenuList ref={sliderTrack} arrowsVisible={arrowsVisible}>
        {children}
      </SliderMenuList>
      <ArrowWrapper visible={arrowsVisible} onClick={slideNext}>
        <NavigationArrow />
      </ArrowWrapper>
    </SliderMenuWrapper>
  )
}

export default SliderMenu
