import React from "react"
import styled from "styled-components"
import loaderGif from "../../images/loader.gif"
import { AppFunctionComponent } from "../../types"

const LoadingParagraph = styled.p`
  text-align: center;
  margin: 6em auto;
  color: ${({ theme }) => theme.color.annotation};
`

const LoaderImage = styled.img`
  width: 100px;
`

const Loader: AppFunctionComponent = ({ children, className = "" }) => (
  <LoadingParagraph className={className}>
    {children || <LoaderImage src={loaderGif} alt={"Loading..."} />}
  </LoadingParagraph>
)

export default Loader
