import React, { useState } from "react"
import styled from "styled-components"
import { QuinaryHeading } from "../../common/heading.component"
import ResourceModal from "./resource-modal.component"
import { AppFunctionComponent } from "../../../types"
import { BasicButton } from "../../../ui/components/button/button.component"
import { QueryResult } from "../../../types"
import { Tag as TagProps } from "./resource.interface"

const Content = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${({ theme }) => theme.color.background};
`

const MissHeading = styled(QuinaryHeading)`
  text-align: center;
  margin: 0 0 15px 0;
`

const MissParagraph = styled.p`
  text-align: center;
  max-width: 400px;
`

const MissButton = styled(BasicButton)`
  margin: 36px auto 0 auto;
`
type ResourceMissItemProps = {
  tags: QueryResult<TagProps>
}

const ResourceMissItem: AppFunctionComponent<ResourceMissItemProps> = ({
  tags,
}) => {
  const [active, setModal] = useState(false)
  const showModal = () => setModal(true)
  const hideModal = () => setModal(false)
  return (
    <li>
      <Content>
        <MissHeading renderAs={"h5"}>Did we miss something?</MissHeading>
        <MissParagraph>
          Send your ideas, articles, books, or people you think should be
          featured here
        </MissParagraph>
        <MissButton onClick={showModal}>Submit recommendation</MissButton>
        <ResourceModal tags={tags} hideModal={hideModal} active={active} />
      </Content>
    </li>
  )
}

export default ResourceMissItem
