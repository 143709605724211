export enum QueryName {
  Tag = "tag",
}

export enum ContentType {
  Resource = "resource",
  Category = "resourceCategory",
  Tag = "tag",
  Blog = "blogPost",
}
