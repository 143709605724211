import React, {
  ChangeEvent,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react"
import styled from "styled-components"
import { AppFunctionComponent } from "../../../types"
import { Fieldset } from "../text-input.component"

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string
  error?: string
  resize?: string
  value: any
  minLength: number
  onValueChange: (value: string) => void
}

export const Textarea = styled.textarea<{
  resize?: "vertical" | "horizontal" | "none" | string
  error?: boolean
}>`
  width: 100%;
  z-index: ${({ theme }) => theme.zIndexes.midLayer};
  border: none;
  height: 130px;
  min-height: 64px;
  outline: none;
  padding: 34px 15px 0;
  appearance: none;
  position: relative;
  box-sizing: border-box;
  font-size: ${14 / 16}rem;
  border-radius: ${({ theme }) => theme.size.borderRadius};
  box-shadow: 0 2px 20px 0 ${({ theme }) => theme.color.lightShadow};
  background-color: ${({ theme }) => theme.color.backgroundInverted};
  resize: ${({ resize }) => (resize ? resize : "auto")};

  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.color.error : theme.color.commerceBorder};

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    font-size: 1rem;
  }
  &::placeholder {
    color: ${({ theme }) => theme.color.placeholder};
  }
`

const TextareaFieldset = styled(Fieldset)`
  margin: 0 0 30px 0;
  max-height: none;
`

const Optional = styled.span`
  right: 10px;
  bottom: 10px;
  z-index: ${({ theme }) => theme.zIndexes.frontLayer};
  color: ${({ theme }) => theme.color.placeholder};
  font-size: ${12 / 16}rem;
  font-weight: 300;
  position: absolute;
  pointer-events: none;
`

// eslint-disable-next-line jsx-a11y/label-has-associated-control
const Label = styled(({ focused, empty, ...rest }) => <label {...rest} />)<{
  focused: boolean
  empty: boolean
}>`
  z-index: 2;
  left: 20px;
  cursor: text;
  font-weight: 300;
  position: absolute;
  transition: all 0.1s ease-in-out;
  font-size: ${({ focused, empty }) =>
    focused || !empty ? `${12 / 16}rem` : `1rem`};
  top: ${({ focused, empty }) => (focused || !empty ? `16px` : `25px`)};
  color: ${({ theme }) => theme.color.placeholder};
  @media (max-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    left: 15px;
    font-size: ${({ focused, empty }) =>
      focused || !empty ? `${12 / 16}rem` : `${15 / 16}rem`};
  }
`

const TextareaInput: AppFunctionComponent<TextareaProps> = ({
  className,
  name,
  value,
  label,
  placeholder,
  onValueChange,
  error = "",
  minLength,
  maxLength,
  resize,
}) => {
  const [focused, setFocused] = useState(false)
  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (focused && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focused])

  const handleFocus = () => setFocused(!focused)
  const handleBlur = () => setFocused(!focused)
  const handleClick = () => setFocused(true)
  const handleChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    onValueChange(newValue)
  }
  const errorValidation = Boolean(value.length < minLength && error.length)
  return (
    <TextareaFieldset onClick={handleClick} className={className}>
      {!errorValidation && (
        <Label htmlFor={name} focused={focused} empty={!value}>
          {label}
        </Label>
      )}
      <Textarea
        ref={inputRef}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={!focused ? "" : placeholder}
        name={name}
        value={value}
        id={name}
        aria-label={label}
        maxLength={maxLength}
        resize={resize}
        error={Boolean(error)}
        data-testid="textarea"
      />
      <Optional>{`* optional`}</Optional>
    </TextareaFieldset>
  )
}

export { TextareaInput }
