import { globalHistory } from "@reach/router"
import { useEffect, useState } from "react"
import { useLocation } from "react-use"

export function dynamicSearchParams() {
  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(globalHistory.location.search)
  )
  const currentLocation = useLocation()

  useEffect(() => {
    setSearchParams(new URLSearchParams(currentLocation.search))
  }, [currentLocation.search])

  return [searchParams]
}

export function onQueryChange(callback: () => any) {
  const currentLocation = useLocation()

  useEffect(() => {
    callback()
  }, [currentLocation.search])
}
