import React from "react"
import styled from "styled-components"
import { AppFunctionComponent } from "../../../types"
import { NeutralTextLink } from "../../../ui/components/link/link.component"
import SliderMenu from "../../common/slider-menu"

const NavLink = styled(NeutralTextLink)`
  margin-right: 35px;
  color: inherit;
  white-space: nowrap;

  &.active {
    color: ${({ theme }) => theme.color.link};
  }
`

interface LinkProps {
  to: string
  text: string
}

interface Props {
  links: LinkProps[]
}

const ResourcesMenu: AppFunctionComponent<Props> = ({ links }) => (
  <SliderMenu>
    {links.map((link) => (
      <li key={link.text}>
        <NavLink
          to={"/community/resources/" + link.to}
          activeClassName={"active"}
          partiallyActive
        >
          {link.text}
        </NavLink>
      </li>
    ))}
  </SliderMenu>
)

export default ResourcesMenu
