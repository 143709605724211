import React from "react"
import styled from "styled-components"
import { PrimaryHeading } from "./heading.component"
import { BasicButton } from "../../ui/components/button/button.component"
import { AppFunctionComponent } from "../../types"

const Container = styled.div`
  margin-top: 50px;
  width: 100%;
  overflow: hidden;
  text-align: center;
`

const Heading = styled(PrimaryHeading)`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    margin: 40px 18%;
  }
`

const ThanksButton = styled(BasicButton)`
  margin: 0 auto;
`

interface Props {
  onClose: () => void
  text: string
  buttonText: string
}

const Thanks: AppFunctionComponent<Props> = ({
  children,
  onClose,
  text,
  buttonText,
}) => (
  <Container>
    {children}
    <Heading renderAs="h2">{text}</Heading>
    <ThanksButton onClick={onClose}>{buttonText}</ThanksButton>
  </Container>
)

export default Thanks
