import React from "react"
import { AppFunctionComponent } from "../../../types"
import { PrimaryHeading } from "../../common/heading.component"
import StandardSection from "../../common/sections/section.component"

const ResourcesInfo: AppFunctionComponent = () => (
  <StandardSection>
    <PrimaryHeading renderAs={"h1"}>Resources</PrimaryHeading>
    <p>
      We continuously hand-pick the most valuable resources on technology and
      how it influences us.
      <br />
      Leave comments and suggest new assets to add to our library.
    </p>
  </StandardSection>
)

export default ResourcesInfo
